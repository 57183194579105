<template>
    <e-commerce-checkout-step-cart/>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ref } from '@vue/composition-api'
import ECommerceCheckoutStepCart from './ECommerceCheckoutStepCart.vue'
import store from '@/store'
export default {
  components: {
    FormWizard,
    TabContent,
    ECommerceCheckoutStepCart,
  },
  computed: {
    isCashier() {
      return this.$store.getters['user/userData'].roles.find(role => role.name == 'cashier')
    },
    cartLength(){
      return this.$store.state['app-ecommerce'].cartItems.length
    },
  },
  mounted() {
    if(!this.isCashier && this.cartLength < 0) {
      store.dispatch('app-ecommerce/fetchCartProducts')
    }
  },
  setup() {
    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }
    
    return {
      refFormWizard,
      formWizardNextStep,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style lang="scss" scoped></style>
