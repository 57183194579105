<template>
  <div class="list-view mt-0">
    <b-row>
      <b-col cols="12" md="6">
        <e-commerce-checkout-step-cart-products />
      </b-col>
      <b-col cols="12" md="6">
        <e-commerce-checkout-step-payment />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BCard, BInputGroup, BFormInput, BInputGroupAppend, BRow, BCol,
} from 'bootstrap-vue'
import ECommerceCheckoutStepCartProducts from './ECommerceCheckoutStepCartProducts.vue'
import ECommerceCheckoutStepPayment from './ECommerceCheckoutStepPayment.vue'

export default {
  components: {
    // BSV
    BButton,
    BCard,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BRow, BCol,
    // SFC
    ECommerceCheckoutStepCartProducts,
    ECommerceCheckoutStepPayment
  },
  
}
</script>
