<template>
  <div class="checkout-items">
    <div v-if="isCashier">
      <v-select
        :loading="loadingSearchProduct"
        v-model="selected"
        @input="changeProductQuantity"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="allProducts"
        label="name"
        placeholder="Seleccione un producto"
        :reduce="(item) => item"
      >
        <template v-slot:option="option">
          {{ option.name }}
        </template>
      </v-select>
    </div>
    <br />
    <vuescroll
      v-if="cartProducts.length"
      :ops="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      style="height: calc(110vh - 180px)"
      tagname="li"
    >
      <b-card
        v-for="(product, indexTr) in cartProducts"
        :key="indexTr"
        class="ecommerce-card"
        no-body
      >
        <!-- Product Image -->
        <div class="item-img">
          <b-link>
            <b-img
              :src="product.image"
              :alt="`${product.name}-${product.id}`"
            />
          </b-link>
        </div>

        <!-- Product Details: Card Body -->
        <b-card-body>
          <div class="item-name">
            <h6 class="mb-0">
              <b-link class="text-body">
                {{ product.name }}
              </b-link>
            </h6>
            <span v-if="product.category" class="item-company">
              {{ product.category.name }}
            </span>
          </div>
          <span v-if="product.stock" class="text-success mb-1"
            >Disponible {{ product.stock - product.qty }}</span
          >
          <div class="item-quantity">
            <span class="quantity-title">Cantidad:</span>
            <div>
              <b-form-input
                v-model="product.qty"
                type="number"
                @input="updateQtyInProduct($event, product)"
                :max="product.stock"
                size="sm"
                class="ml-75"
              />
            </div>
          </div>
          <span
            class="delivery-date text-muted"
            v-if="product.discount_type == 'percent'"
          >
            Descuento <span class="text-success">{{ product.discount }}%</span>
          </span>
        </b-card-body>

        <!-- Product Options/Actions -->
        <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <h4 class="item-price">
                {{ product.price | currency({ symbol: "$", precision: 2 }) }}
              </h4>
              <small>
                {{
                  ($store.state.bank.data.amount * product.price)
                    | currency({ symbol: "VEF", precision: 2 })
                }}
              </small>
            </div>
          </div>
          <b-button
            variant="light"
            class="mt-1 remove-wishlist"
            @click="removeProductFromCart(product)"
          >
            <feather-icon icon="XIcon" class="mr-50" />
            <span>Borrar</span>
          </b-button>
        </div>
      </b-card>
    </vuescroll>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BLink,
  BImg,
  BButton,
  BBadge,
  BFormSpinbutton,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import vuescroll from "vuescroll";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    BBadge,
    BFormSpinbutton,
    vSelect,
    vuescroll,
    BFormInput,
  },
  computed: {
    cartProducts() {
      return this.$store.state["app-ecommerce"].cartItems.slice().reverse();
    },
    isCashier() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "cashier"
      );
    },
  },
  props: {
    product: Object,
  },
  watch: {
    "product.qty"(newVal) {
      this.localQty = newVal;
    },
  },
  data() {
    return {
      localQty: this.product ? this.product.qty : 0,
      loadingSearchProduct: false,
      selected: null,
      allProducts: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
        rail: {
          background: "#01a99a",
        },
        bar: {
          background: "#ee3926",
        },
      },
    };
  },
  mounted() {
    if (this.isCashier) {
      this.getProducts();
    }
  },
  methods: {
    changeProductQuantity(product) {
      this.$store.commit("app-ecommerce/UPDATE_PRODUCT_CART", product);
      this.selected = null;
    },
    updateQtyInProduct($event, product) {
      const newQty = parseInt($event, 10);
      if (!isNaN(newQty)) {
        if (newQty <= product.stock) {
          product.qty = newQty;
          this.$store.commit("app-ecommerce/UPDATE_PRODUCT_CART", product);
        } else {
          product.qty = product.stock;
          this.$store.commit("app-ecommerce/UPDATE_PRODUCT_CART", product);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertCircleIcon",
              variant: "danger",
              text: "La cantidad solicitada supera el stock disponible. Se ha ajustado a la cantidad máxima disponible.",
            },
          });
        }
      }
    },

    removeProductFromCart(product) {
      this.$store.commit("app-ecommerce/REMOVE_PRODUCT_FROM_CART", product.id);
      this.$store.commit(
        "app-ecommerce/UPDATE_CART_ITEMS_COUNT",
        this.$store.state["app-ecommerce"].cartItemsCount - 1
      );
    },
    async getProducts() {
      try {
        this.loadingSearchProduct = true;
        this.allProducts = await this.$store.dispatch("products/index", {
          store: true,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingSearchProduct = false;
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
[dir] .b-form-spinbutton.form-control .btn-sm,
[dir] .b-form-spinbutton.form-control .btn-group-sm > .btn {
  padding: 0 !important;
}
</style>
